<template>
  <el-container>
    <!-- 头部 -->
    <el-header>
      <div class="logo">
        <div class="header_logo">
          <img style="height:60px" src="../assets/logo-1.png" alt />
        </div>
        <!-- <div class="toggle-button ml20" @click="togleCollapse">
          <i class="el-icon-s-fold f20" ref="icon"></i>
        </div> -->
      </div>
      <div class="user flex align-center">
        <div class="userInfo">
          <span class="vm">{{ userName }}</span>
          <!-- <img
            :src="imageUrl"
            class="vm round ml10"
            alt
            width="32"
            height="32"
            onerror="this.src='https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2100401123,2895311668&fm=26&gp=0.jpg'"
          /> -->
        </div>
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="el-icon-more" style="color:#000;cursor: pointer;"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="setting">账户设置</el-dropdown-item>
            <el-dropdown-item command="logout">登出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <!-- 主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '230px'">
        <el-menu
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="$route.path"
          background-color="#fff"
          text-color="#000"
        >
          <el-menu-item-group
            :index="item.link"
            v-for="item in menuList"
            :key="item.menuCode"
          >
            <!-- 一级菜单  -->
            <el-menu-item
              :index="item.link"
              @click="stairSaveNavState('/' + item.link, item)"
              v-if="!item.childrens || item.childrens.length == 0"
            >
              <i :class="item.iconUrl"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>

            <!-- 有二级的菜单 -->
            <el-submenu
              v-if="item.childrens && item.childrens.length"
              :index="item.link"
            >
              <template slot="title">
                <i :class="item.iconUrl"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item
                :index="i.link"
                @click="stairSaveNavState('/' + i.link, i)"
                v-for="i in item.childrens"
                :key="i.menuCode"
              >
                <template slot="title">
                  <i :class="i.iconUrl"></i>
                  <span>{{ i.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu-item-group>
        </el-menu>
      </el-aside>
      <!-- 内容主体 -->
      <div style="width: calc(100vw - 230px);">
        <el-tabs
          v-model="activeTab"
          type="border-card"
          closable
          @tab-click="tabClick"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            :key="item.name"
            v-for="item in tabsItem"
            :label="item.title"
            :name="item.name"
          >
            <template style="">
              <!-- <router-view></router-view> -->
            </template>
          </el-tab-pane>
        </el-tabs>
        <el-main class="main-center">
          <div class="template-tabs">
            <router-view></router-view>
          </div>
        </el-main>
      </div>
    </el-container>
    <div id="loader-wrapper" v-show="false">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
      <div class="load_title">
        正在载入,请耐心等待..
        <br />
        <!-- <span>V1.0</span> -->
      </div>
    </div>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 左侧菜单数据
      menuList: [],
      tabsItem: JSON.parse(sessionStorage.getItem("tabsItem"))
        ? JSON.parse(sessionStorage.getItem("tabsItem"))
        : [],
      iconObj: {
        1: "el-icon-s-home",
        2: "iconfont icon-user",
        3: "iconfont icon-tijikongjian",
        4: "iconfont icon-shangpin",
        5: "iconfont icon-danju",
        6: "iconfont icon-baobiao",
        7: "iconfont icon-baobiao",
        8: "iconfont icon-danju"
      },
      // 默认不折叠
      isCollapse: false,
      // 被激活导航地址
      keyvalue: "",
      activePath: "/member",
      userName: "",
      showLoading: true,
      imageUrl: "",
      activeTab: sessionStorage.getItem("activeTab")
        ? sessionStorage.getItem("activeTab")
        : "1", //默认显示的tab
      tabIndex: 1 //tab目前显示数
    };
  },
  created() {
    // this.getMenuList()
    this.activePath = window.sessionStorage.getItem("activePath");
    this.userName = JSON.parse(localStorage.getItem("userName"));
    this.getUserInfo();
  },
  computed: {
    setHeight() {
      return document.documentElement.clientHeight - 65;
    },
    activeNav() {
      //当前激活的导航
      return this.$route.path;
    }
  },
  mounted() {
    this.getMenuList();
  },
  methods: {
    addTab(menu) {
      //判断是否已经存在了
      let menuExsit = false;
      for (var item of this.tabsItem) {
        if (menu.menuCode == item.menuCode) {
          menuExsit = true;
          this.activeTab = item.name;
          sessionStorage.setItem("activeTab", item.name);
        }
      }
      if (!menuExsit) {
        //不存在
        let newTabName = menu.name;
        this.tabsItem.push({
          title: menu.name,
          name: newTabName,
          link: menu.link,
          menuCode: menu.menuCode
        });
        this.activeTab = newTabName;
        sessionStorage.setItem("tabsItem", JSON.stringify(this.tabsItem));
        sessionStorage.setItem("activeTab", newTabName);
        this.$router.push({ path: menu.link });
      }
    },
    removeTab(targetName) {
      let tabs = this.tabsItem;
      let activeName = this.activeTab;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.activeTab = activeName;
      this.tabsItem = tabs.filter(tab => tab.name !== targetName);
      sessionStorage.setItem("tabsItem", JSON.stringify(this.tabsItem));
      sessionStorage.setItem("activeTab", activeName);
    },
    tabClick(tab) {
      let path = "";
      let menuName = tab.$options.propsData.name;
      for (var item of this.tabsItem) {
        if (menuName == item.name) {
          path = item.link;
        }
      }
      this.$router.push({ path: path });
    },
    // 获取请求菜单
    async getMenuList() {
      var that = this;
      var loginName = this.getLocalStorage("loginName");
      // console.log('index loginName',loginName)
      //存储超时，跳转到登陆页
      if (loginName == null || loginName == "") {
        //用户名不存在跳转登陆
        that.$router.push({ path: "/login" });
        return;
      }
      this.$http
        .post("/menuInfo/getUserMenu", {
          loginName: loginName
        })

        .then(function(res) {
          // console.log('index menu',JSON.stringify(res.data.data))
          // that.menuList = res.data.data.splice(1);
          that.menuList = res.data.data;
          console.log(res.data.data);
          that.showLoading = false;
        });
    },
    getLocalStorage(key) {
      // 取出对象
      let item = localStorage.getItem(key);
      // 先将拿到的试着进行json转为对象的形式
      try {
        item = JSON.parse(item);
      } catch (error) {
        // eslint-disable-next-line no-self-assign
        item = item;
      }
      // 如果有startTime的值，说明设置了失效时间
      if (item && item.startTime) {
        let date = new Date().getTime();
        // 如果大于就是过期了，如果小于或等于就还没过期
        if (date - item.startTime > item.expires) {
          localStorage.removeItem(name);
          return "";
        } else {
          return item.value;
        }
      } else {
        return "";
      }
    },
    // 菜单的折叠与展开
    togleCollapse() {
      // console.log(this.$refs)
      if (this.$refs.icon.className == "el-icon-s-fold f20") {
        this.$refs.icon.className = "el-icon-s-unfold f20";
      } else {
        this.$refs.icon.className = "el-icon-s-fold f20";
      }
      this.isCollapse = !this.isCollapse;
    },
    // 保存连接的激活地址
    stairSaveNavState(activePath, menu) {
      window.sessionStorage.setItem("activePath", activePath);
      //主窗口添加显示
      this.addTab(menu);
    },
    // 保存连接的激活地址
    saveNavState(activePath) {
      window.sessionStorage.setItem("activePath", activePath);
    },

    // 监听下拉菜单子选项点击事件
    handleCommand(command) {
      //this.$message("click on item " + command);
      if (command == "logout") {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("userName");
        window.localStorage.removeItem("loginName");
        this.$router.push("/login");
      } else {
        this.$router.push("/myAccountView");
      }
    },

    //获取用户信息
    //获取个人信息
    getUserInfo() {
      var that = this;
      that.$http
        .post("/userInfo/queryOne", { loginName: that.userName })
        .then(function(response) {
          console.log(response.data.data);
          var keyvalue = response.data.data.employeeType;
          var ids = response.data.data.id;
          localStorage.setItem("employeeType", keyvalue);
          localStorage.setItem("ids", ids);
          if (response.data.code == 200) {
            that.imageUrl = response.data.data.logo;
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
// /deep/ .bbox{
//   padding-top:20px !important;
// }
/deep/ .el-tabs__nav-prev {
  line-height: 30px !important;
}
/deep/ .el-tabs__nav-next {
  line-height: 30px !important;
}
/deep/ .el-submenu__title i {
  color: #000;
  font-size: 15px;
  font-weight: 600;
}
/deep/ .el-tabs--border-card > .el-tabs__header {
  border: none;
}
/deep/ .el-tabs__content {
  padding: 0px;
  // margin-bottom: 10px;
}
/deep/ .el-tabs__item {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px;
}
/deep/ .el-menu-item-group__title {
  padding: 0 !important;
}
/deep/.el-menu-item.is-active {
  width: 100%;
  color: #409eff;
  background-color: rgb(240, 246, 255) !important;
}
/deep/.el-submenu .el-menu-item {
  min-width: 150px;
  background-color: #28292b;
}
/deep/.el-submenu.is-active .el-submenu__title {
  color: #37588a;
}
/deep/.el-submenu.is-active .el-submenu__title i {
  color: #000;
}
.el-container {
  height: 100%;
  overflow-y: hidden;
}
.el-header {
  // background-color: #003a64;
  // background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #000;
  font-size: 20px;
  .logo {
    display: flex;
    align-items: center;
    height: 100%;
    .header_logo {
      display: flex;
      align-items: center;
      width: 230px;
      // background-color: #336084;
      background-color: #fff;
      height: 100%;
      justify-content: center;
      border-bottom: 1px solid #e8e8e8;
      img {
        height: 60px;
        // width: 100%;
      }
    }
  }
  .user {
    margin-right: 28px;
    .userInfo {
      margin-right: 40px;
      color: #000;
      font-size: 13px;
    }
    > .el-dropdown-menu {
      top: 57px !important;
    }
  }
}
.el-aside {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    display: none !important;
  }
  .el-menu {
    border: none;
  }
}
/deep/.el-main {
  padding: 20px;
  height: 100%;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  line-height: 24px;
  color: #000;
  text-align: center;
  letter-spacing: 0.2em;
  // 鼠标放上去变成小手
  cursor: pointer;
}

html,
body,
#app {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 1 */
  border-top-color: #fff;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 2 */
  border-top-color: #fff;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #fff;
  /* COLOR 3 */
  -moz-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -webkit-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #7171c6;
  /* Old browsers */
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);
  /* IE 9 */
  transform: translateX(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);
  /* IE 9 */
  transform: translateX(100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);
  /* IE 9 */
  transform: translateY(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #222222;
}
#loader-wrapper .load_title {
  font-family: "Open Sans";
  color: #000;
  font-size: 18px;
  width: 100%;
  text-align: center;
  z-index: 9999999999999;
  position: absolute;
  top: 60%;
  opacity: 1;
  line-height: 30px;
}
#loader-wrapper .load_title span {
  font-weight: normal;
  font-style: italic;
  font-size: 13px;
  color: #000;
  opacity: 0.5;
}
// @media (max-width: 1366px) {
//    #loader {
//     top: 45%;
//   }
// }
</style>
